import React from 'react';
import { LayerViewModel } from './LayerViewModel';
import { NavBarComponente } from '../../Components/NavBarComponente';
import { Container, Row, Image, Col, Form, Button } from 'react-bootstrap';
import fechar from '../../Imagens/close-24.png';
import salvar from '../../Imagens/save-16.png';

import "../../Estilo/Form.css";
import Select from 'react-select';
import { FormEvent } from '../../ScreenGeneric/Models/FormEvent';
import { ToastContainer, toast } from 'react-toastify';
import { LoaderComponente } from "../../Components/LoaderComponente";

interface MyProps {
    navigation: any
    delegate: LayerViewControllerDelegate
    viewModel: LayerViewModel
    idPrimary: number
}

export interface LayerViewControllerDelegate {
    tapDimiss(navigation: any): void
}

export class LayerViewController extends React.Component<MyProps> {

    private formEvent: FormEvent
    state = {
        isLoading: false,
        json: {},
        visible: Array<any>(),
        agroup: Array<any>(),
        typeLayer: Array<any>(),
        imageMarker: Array<any>(),
        layerFather: Array<any>(),
        showLoading: Array<any>()
    }

    constructor(props: any) {
        super(props)
        this.formEvent = new FormEvent(this)
    }

    componentDidMount() {
        this.loadVisible()
        this.loadAgroup()
        this.loadTypeLayer()
        this.loadShowLoading()
        this.loadMarkerImage()
        this.loadLayers()

        if (this.props.idPrimary > 0) {
            this.loadStruct()
        }
    }

    render() {
        var json = this.state.json as any
        return (
            <div>
                <NavBarComponente propNav={this.props.navigation} />
                <div>
                    <Container fluid={true}>
                        <Row>
                            <Col>
                                <div className="title">
                                    <h3>Camadas</h3>
                                </div>
                                <div className="buttonClose">
                                    <Image className="imageClose"
                                        onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)}
                                        src={fechar} />
                                </div>
                            </Col>
                        </Row>
                        <Form className="containerForm">
                            <Row>
                                <Col lg="4" xs="12" className="bottom10 hide">
                                    <Form.Label>Sequencial</Form.Label>
                                    <Form.Control
                                        id="scamada"
                                        defaultValue={json['scamada'] ? json['scamada'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Nome</Form.Label>
                                    <Form.Control
                                        id="camada"
                                        defaultValue={json['camada'] ? json['camada'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("varchar", e.target)}
                                        placeholder="Digite o nome"
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Ordem</Form.Label>
                                    <Form.Control
                                        id="ordem"
                                        defaultValue={json['ordem'] ? json['ordem'] : ''}
                                        onChange={(e: any) => this.formEvent.onChange("inteiro", e.target)}
                                        placeholder="Digite a ordem"
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label className="isRequired">Visível</Form.Label>
                                    <Select
                                        id="visivel"
                                        value={this.formEvent.onValueSelect('select', 'visivel', this.state.visible)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "visivel")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.visible}
                                        isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Tipo de camada</Form.Label>
                                    <Select
                                        id="tipocamada"
                                        value={this.formEvent.onValueSelect('select', 'tipocamada', this.state.typeLayer)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "tipocamada")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.typeLayer}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Marcador de imagem</Form.Label>
                                    <Select
                                        id="smarcadorimagem"
                                        value={this.formEvent.onValueSelect('select', 'smarcadorimagem', this.state.imageMarker)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "smarcadorimagem")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.imageMarker}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Agrupa</Form.Label>
                                    <Select
                                        id="agrupa"
                                        value={this.formEvent.onValueSelect('select', 'agrupa', this.state.agroup)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "agrupa")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.agroup}
                                        isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Camada pai</Form.Label>
                                    <Select
                                        id="scamadapai"
                                        value={this.formEvent.onValueSelect('select', 'scamadapai', this.state.layerFather)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "scamadapai")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.layerFather}
                                        isMulti={false}
                                    />
                                </Col>
                                <Col lg="4" xs="12" className="bottom10">
                                    <Form.Label>Mostrar ao carregar</Form.Label>
                                    <Select
                                        id="displayonload"
                                        value={this.formEvent.onValueSelect('select', 'displayonload', this.state.showLoading)}
                                        onChange={(e: any) => this.formEvent.onChange("select", e, "displayonload")}
                                        closeMenuOnSelect={true}
                                        placeholder={"Selecione"}
                                        options={this.state.showLoading}
                                        isMulti={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" style={{ marginTop: 20 }}>
                                    <Button className="buttonSave" type="button" onClick={(e: any) => this.save()} variant="primary">
                                        <Image className="imageSave"
                                            src={salvar} />
                                        Salvar
                                    </Button>
                                    <Button onClick={(e: any) => this.props.delegate.tapDimiss(this.props.navigation)} className="buttonCancel" variant="link">Cancelar</Button>
                                </Col>
                            </Row>
                        </Form>
                        <ToastContainer />
                    </Container>
                </div>
                <LoaderComponente show={this.state.isLoading} hide={() => this.setState({ isLoading: this.state.isLoading })} />
            </div>
        )
    }

    private save() {
        var json = this.state.json as any
        if (json["camada"] == undefined || json["visivel"] == undefined) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        if (json["camada"].length == 0) {
            this.showFieldsEmpty("Campos obrigatórios não preenchidos!")
            return
        }

        this.props.viewModel.fetchSave(json).then(() => {
            toast.success("Registro incluído com sucesso!", {
                position: toast.POSITION.BOTTOM_RIGHT
            });

            setTimeout(() => {
                this.props.delegate.tapDimiss(this.props.navigation);
            }, 1000);
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        })
    }

    private showFieldsEmpty(message: string) {
        toast.error(message, {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }

    private loadVisible() {
        var array = Array<any>()
        array.push({ value: "S", label: "Sim", check: false })
        array.push({ value: "N", label: "Não", check: false })

        this.setState({
            visible: array
        })
    }

    private loadAgroup() {
        var array = Array<any>()
        array.push({ value: "S", label: "Sim", check: false })
        array.push({ value: "N", label: "Não", check: false })

        this.setState({
            agroup: array
        })
    }

    private loadTypeLayer() {
        var array = Array<any>()
        array.push({ value: "1", label: "Padrão", check: false })
        array.push({ value: "2", label: "Agregado", check: false })
        array.push({ value: "3", label: "Mapa Térmico", check: false })
        array.push({ value: "4", label: "Ortofoto", check: false })

        this.setState({
            typeLayer: array
        })
    }

    private loadMarkerImage() {
        this.props.viewModel.fetchSelectMarkerImage().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for (var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({ value: item.id, label: item.value, check: false })
            }

            this.setState({
                imageMarker: array
            })
        });
    }

    private loadLayers() {
        this.props.viewModel.fetchSelectLayers().then((rs) => {
            var result = rs as Array<any>
            var array = Array<any>()

            for (var i = 0; i < result.length; i++) {
                var item = result[i]
                array.push({ value: item.id, label: item.value, check: false })
            }

            this.setState({
                layerFather: array
            })
        });
    }

    private loadShowLoading() {
        var array = Array<any>()
        array.push({ value: "S", label: "Sim", check: false })
        array.push({ value: "N", label: "Não", check: false })

        this.setState({
            showLoading: array
        })
    }

    private loadStruct() {
        var json = this.state.json as any
        json['scamada'] = this.props.idPrimary

        this.setState({ isLoading: true })
        this.props.viewModel.fetchDataOne(json).then((rs) => {
            var resultado = rs as Array<any>
            if (resultado.length > 0) {
                this.setState({
                    isLoading: false,
                    json: resultado[0]
                })

                return
            }

            this.setState({ isLoading: false })
        }, e => {
            toast.error(JSON.stringify(e), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        });
    }
}