import * as L from 'leaflet';
import { GeoJSON, Icon, Marker, Point, divIcon, markerClusterGroup } from 'leaflet';
import React, { createRef } from 'react';
import { Col, Form, Image, Row } from 'react-bootstrap';
import { Map, TileLayer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import '@kalisio/leaflet-graphicscale'
import '@kalisio/leaflet-graphicscale/dist/Leaflet.GraphicScale.min.css';
import '../Plugins/leaflet-compass';
import "../Estilo/leaflet-compass.css";
import '../Plugins/leaflet-measure';
import "../Estilo/leaflet-measure.css";
import "leaflet.browser.print/dist/leaflet.browser.print"
import "../Plugins/L.LatLng"
import "../Plugins/L.Legend"

import moment from 'moment';
import "../Estilo/Mapa.css";
import filter from '../Imagens/doc-filter-24.png';
import { Request } from '../Servidor/Request';
import { CamadaComponente } from './CamadaComponente';
import { DesenharMapaComponente } from './DesenharMapaComponente';
import { FilterInMapComponent } from './FiltrosMapa/FiltrosEmMapaComponente';
import { LoaderComponente } from './LoaderComponente';
import { ModalSearchMapComponent } from './ModalSearchMapComponent';
var cloneLayer = require('leaflet-clonelayer');

interface MyProps {
    height: number,
    isDraw: boolean,
    isCamada: boolean,
    isSearch: boolean,
    isFilter: boolean,
    getMap: (map: any) => any,
    getClickShape: (e: any) => any,
    startEdit: () => any
    saveEdit: () => any
    cancelEdit: () => any
    stopDraw: () => any
    clickRight?: (obj: any) => any
    startDrawShape?: () => any
    cameraPosition?: any
}

class BoundBox {
    private left: number
    private top: number
    private bottom: number
    private right: number

    constructor(left: number, top: number, bottom: number, right: number) {
        this.left = left
        this.top = top
        this.bottom = bottom
        this.right = right
    }
}

type tipoMapa = "gh" | "gs" | "gt" | "gr" | "mb" | "os" | "nh"
export class MapaComponente extends React.Component<MyProps> {

    private map: any;
    private layersAtivos: any;
    private shapesAtivos: any;
    private shapesFiltros: Array<number>;
    private listLayersAtivos: Array<number>;
    private boundBox: BoundBox;
    private tilersAtivos: any;
    private pageSizePDF: string;
    private latLngControl: any;
    private shapeLegend: Array<any>;
    private layerClick: any;
    constructor(props: any) {
        super(props);
        this.map = createRef();
        this.layersAtivos = {};
        this.shapesAtivos = {};
        this.shapesFiltros = new Array<number>();
        this.listLayersAtivos = new Array();
        this.boundBox = new BoundBox(0, 0, 0, 0)
        this.tilersAtivos = {}
        this.pageSizePDF = "B8"
        this.latLngControl = null
        this.shapeLegend = new Array<any>()
    }

    state = {
        lat: -30.1087957,
        lng: -51.3172251,
        zoom: 10,
        tipoMap: "gs",
        tokenMapBox: "pk.eyJ1IjoiZGFuaWVscmFtYm8iLCJhIjoiY2o3ejRveWFtNDdhZDJ3bzM1aXp0YTQxZiJ9.ZJ3EXfv0fY71XrD3ZmPJDg",
        tokenGoogle: "AIzaSyCXDmzBDlnV7R1Yg1V9q0Hd9Y6aEqvpHEs",
        arrayGeoJson: new Array<any>(),
        showLoading: false,
        showAddShape: false,
        showSearch: false,
        openFilter: false,
        openCamada: false,
        selectedLayers: Array<{ value: string | number, label: string }>(),
        storagedFilters: {},
        latlng: { latitude: -30.1087957, longitude: -51.3172251 }
    }


    componentDidMount() {
        this.buscarPosicaoAtual();
        this.checkStorage()

        let control = L.control as any
        control.graphicScale({ fill: 'fill' }).addTo(this.map);
        control.measure({ position: 'topleft' }).addTo(this.map);
        control.browserPrint({
            position: 'bottomright',
            printLayer: L.tileLayer('https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'),
            printModes: [(L as any).BrowserPrint.Mode.Landscape(this.pageSizePDF)]
        }).addTo(this.map);

        let controlMap = L.Control as any
        this.map.addControl(new controlMap.Compass({ position: 'bottomright' }));
        this.latLngControl = (L as any).latLngControl({ position: 'bottomleft' }).addTo(this.map)

        var legendPrintControl = (L as any).legendControl({ position: 'bottomleft' })
        this.map.on((L as any).BrowserPrint.Event.PrintInit, (e: any) => {
            this.setState({ showLoading: true })

            legendPrintControl.addTo(this.map)
            let infoLegend = new Array<any>()
            this.shapeLegend.forEach((item) => {
                let geom = item.geom
                let geomType = geom.type
                if(geomType == 'Point' || geomType == 'MultiPoint') { 
                    infoLegend.push({type: 'ponto', image: 'https://storage.googleapis.com/gep_images_production/geo/marker_vermelho.png', layer: item['camada']})
                } else if(geomType == 'Polygon' || geomType == 'MultiPolygon') {
                    infoLegend.push({type: 'poligono', color: '#FF0000', layer: item['camada']})
                } else {
                    infoLegend.push({ type: 'linha', color: '#FF0000', layer: item['camada'] })
                }
            })

            legendPrintControl.update({ list: infoLegend })
        })

        this.map.on((L as any).BrowserPrint.Event.Print, (e: any) => {
            let mapPrint = e.printMap
            var hasRemovePoint = false
            var hasRemovePolygon = false
            var hasRemoveLine = false

            this.shapeLegend.forEach((item) => {
                let geom = item.geom 
                let geomType = geom.type 
                if(geomType == 'Point' || geomType == 'MultiPoint') {
                    if(!hasRemovePoint) {
                        mapPrint.eachLayer((layer: any) => {
                            if (layer instanceof L.Marker) {
                                mapPrint.removeLayer(layer);
                            }
                        });

                        hasRemovePoint = true
                    }

                    let coordinates = geom.coordinates
                    let lat = coordinates[1]
                    let lng = coordinates[0]

                    let marker = new L.Marker({ lat: lat, lng: lng })
                    let icon = new Icon({
                        iconUrl: 'https://storage.googleapis.com/gep_images_production/geo/marker_vermelho.png'
                    })

                    marker.setIcon(icon)
                    mapPrint.addLayer(marker)
                } else if (geomType == 'Polygon' || geomType == 'MultiPolygon') {
                    if (!hasRemovePolygon) {
                        mapPrint.eachLayer((layer: any) => {
                            if (layer instanceof L.Polygon) {
                                mapPrint.removeLayer(layer);
                            }
                        });

                        hasRemovePolygon = true
                    }

                    let layerClone = cloneLayer(this.layerClick)
                    mapPrint.addLayer(layerClone)
                } else {
                    if (!hasRemoveLine) {
                        mapPrint.eachLayer((layer: any) => {
                            if (layer instanceof L.Polyline) {
                                mapPrint.removeLayer(layer);
                            }
                        });

                        hasRemoveLine = true
                    }

                    let layerClone = cloneLayer(this.layerClick)
                    mapPrint.addLayer(layerClone)
                }
            })

            control.graphicScale({ fill: 'fill' }).addTo(mapPrint);
            mapPrint.addControl(new controlMap.Compass({ position: 'topleft' }));
            var latLngPrintControl = (L as any).latLngControl({ position: 'bottomleft' }).addTo(mapPrint)
            latLngPrintControl.update(this.state.latlng)

            legendPrintControl.addTo(mapPrint)
            let infoLegend = new Array<any>()
            this.shapeLegend.forEach((item) => {
                let geom = item.geom
                let geomType = geom.type
                if(geomType == 'Point' || geomType == 'MultiPoint') { 
                    infoLegend.push({type: 'ponto', image: 'https://storage.googleapis.com/gep_images_production/geo/marker_vermelho.png', layer: item['camada']})
                } else if(geomType == 'Polygon' || geomType == 'MultiPolygon') {
                    infoLegend.push({type: 'poligono', color: this.layerClick.feature.style.fillColorClick, layer: item['camada']})
                } else {
                    infoLegend.push({ type: 'linha', color: this.layerClick.feature.style.colorClick, layer: item['camada'] })
                }
            })

            legendPrintControl.update({ list: infoLegend })
            this.setState({ showLoading: false })
            document.querySelector('body')?.removeAttribute('class')
        })

        let browserPrint = document.getElementsByClassName('leaflet-control-browser-print')
        if (browserPrint.length > 0) {
            document.getElementsByClassName('leaflet-control-browser-print').item(0)?.remove()
        }
    }

    private checkStorage() {
        let haveFilter = JSON.parse(String(localStorage.getItem('filters')))
        if (haveFilter) {
            if (moment(new Date()).format('DD/MM/YYYY HH:mm:ss') > haveFilter.expireDate) {
                localStorage.removeItem('filters')
                localStorage.removeItem('layerOptions')
                localStorage.removeItem('selectedLayer')
            } else {
                this.setState({
                    storagedFilters: haveFilter,
                    openFilter: true
                })
            }
        }
    }

    private setMap(map: any) {
        if (!map) {
            this.props.getMap(this.map);
            return;
        }

        this.map = map.leafletElement;
        this.props.getMap(this.map);
    }

    render() {
        return (
            <Map
                editable={true}
                key="mapa"
                oncontextmenu={(e: any) => {
                    if (this.props.clickRight != undefined) {
                        this.props.clickRight(e)
                    }
                }}
                onmousemove={(e: any) => this.moveMouse(e)}
                ref={(e: any) => this.setMap(e)}
                center={this.props.cameraPosition == undefined ? { lat: this.state.lat, lng: this.state.lng } : this.props.cameraPosition}
                zoom={this.state.zoom}
                style={{ height: this.props.height }} >
                {this.state.tipoMap == "gh" &&
                    <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: this.state.tokenGoogle }} type={'hybrid'} crossOrigin={true} />
                }
                {this.state.tipoMap == "gs" &&
                    <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: this.state.tokenGoogle }} type={'satellite'} />
                }
                {this.state.tipoMap == "gt" &&
                    <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: this.state.tokenGoogle }} type={'terrain'} />
                }
                {this.state.tipoMap == "gr" &&
                    <ReactLeafletGoogleLayer googleMapsLoaderConf={{ KEY: this.state.tokenGoogle }} type={'roadmap'} />
                }
                {this.state.tipoMap == "mb" &&
                    <TileLayer url={"https://api.mapbox.com/v4/mapbox.streets-satellite/{z}/{x}/{y}.png?access_token=" + this.state.tokenMapBox} />
                }
                {this.state.tipoMap == "os" &&
                    <TileLayer url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"} />
                }
                {this.props.isDraw &&
                    <DesenharMapaComponente
                        saveEdit={() => this.props.saveEdit()}
                        startEdit={() => this.props.startEdit()}
                        cancelEdit={() => this.cancelEdit()}
                        stopDraw={() => this.props.stopDraw()}
                        startAddShape={() => this.startDrawShape()}
                        getMap={() => { return this.map }} />}
                {this.gerarOpcoesMapa()}
                {this.props.isCamada &&
                    <CamadaComponente
                        openCamada={(isOpen: Boolean) => this.setState({
                            openCamada: isOpen
                        })}
                        isOpenCamada={this.state.openCamada}
                        isOpenFilter={this.state.openFilter}
                        setGeoJson={(scamada: Array<any>) => this.setLayersAtivos(scamada)}
                        setSelectedLayers={(selectedLayersArray: any) => this.setState({ selectedLayers: selectedLayersArray })}
                        selectedLayers={this.state.selectedLayers}
                        removeGeoJSON={(scamada: number) => this.deleteLayer(scamada)}
                        getMap={() => { return this.map }}
                        isEdit={this.props.isDraw}
                        openFilter={(isOpen: Boolean) => this.setState({ openFilter: isOpen })}
                    />}
                {this.props.isFilter &&
                    <FilterInMapComponent
                        getMap={() => { return this.map }}
                        openFilter={(isOpen: Boolean) => this.setState({ openFilter: isOpen })}
                        openCamada={(isOpen: Boolean) => this.setState({ openCamada: isOpen })}
                        selectedLayers={this.state.selectedLayers}
                        isOpenFilter={this.state.openFilter}
                        setGeoJson={(scamada: Array<any>, geojson: Array<any>, tiles: Array<any>, isFilter: boolean) => this.setGeoJSON(scamada, geojson, tiles, isFilter)}
                        clearAllLayers={() => this.clearAllLayers()}
                        storagedFilters={this.state.storagedFilters}
                    />}


                {this.props.isSearch &&
                    <Row>
                        <Col xs={12}>
                            <div id="procurar"
                                className="procurar"
                                onClick={() => this.setState({ showSearch: true })}>
                                <Image
                                    title={"Procurar"}
                                    style={{ marginLeft: 2, marginTop: 2, cursor: "pointer" }}
                                    src={filter}
                                    width={24} />
                            </div>
                        </Col>
                    </Row>
                }
                {this.state.showSearch &&
                    <ModalSearchMapComponent
                        isOpenFilter={this.state.openFilter}
                        isOpenCamada={this.state.openCamada}
                        close={() => this.setState({ showSearch: false })}
                        tapLocation={(location: any) => this.setState({ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude), zoom: 20 })}
                        shapesFilter={(shapes: Array<number>) => this.setFilterShapesStyle(shapes)} />}
                {this.state.arrayGeoJson.length > 0 && this.state.arrayGeoJson}
                <LoaderComponente show={this.state.showLoading} hide={() => this.setState({ showLoading: this.state.showLoading })} />
            </Map>
        )
    }

    private startDrawShape() {
        if (this.props.startDrawShape != undefined) {
            this.props.startDrawShape()
        }
    }

    private onLayerClick(e: any) {
        this.layerClick = e.target
        let geomType = this.layerClick.feature.geometry.type
        if(geomType == 'Point') {
            var coordinates = this.layerClick.feature.geometry.coordinates as Array<any>
            let latlng = new L.LatLng(coordinates[1], coordinates[0])
            this.map.panTo(latlng)
        } else if(geomType == 'MultiPoint') {
            let coordinates = this.layerClick.feature.geometry.coordinates as Array<Array<any>>
            let latlng = new L.LatLng(coordinates[0][1], coordinates[0][0])
            this.map.panTo(latlng)
        } else {
            this.map.panTo(this.layerClick.getBounds().getCenter())
        }

        this.props.getClickShape(e)
    }

    private gerarOpcoesMapa() {

        return (
            <div className={"opcoesMapa"}>
                <Form style={{ marginTop: 5, marginRight: 10, marginLeft: 10 }}>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "gh"}
                                    onChange={() => this.onChangeMapa("gh")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Google Híbrido</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "gs"}
                                    onChange={() => this.onChangeMapa("gs")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Google Satélite</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "gt"}
                                    onChange={() => this.onChangeMapa("gt")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Google Terreno</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "gr"}
                                    onChange={() => this.onChangeMapa("gr")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Google Roteiro</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "mb"}
                                    onChange={() => this.onChangeMapa("mb")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Mapbox</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "os"}
                                    onChange={() => this.onChangeMapa("os")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Open Street</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Form.Check
                                style={{ fontSize: 12, float: "left" }}
                                type={"radio"}>
                                <Form.Check.Input
                                    checked={this.state.tipoMap == "nh"}
                                    onChange={() => this.onChangeMapa("nh")}
                                    name={"tipoMapa"}
                                    type={"radio"} />
                                <Form.Check.Label style={{ marginTop: 3, marginBottom: 5 }}>Nenhum</Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                </Form>
            </div>
        )

    }

    private onChangeMapa(tipoMap: tipoMapa) {
        this.setState({
            tipoMap: tipoMap
        }, () => {
            let keysTiles = Object.keys(this.tilersAtivos)
            for (var i = 0; i < keysTiles.length; i++) {
                var key = keysTiles[i]
                var tile = this.tilersAtivos[key]
                this.map.removeLayer(tile)
                tile.addTo(this.map)
            }
        });
    }

    private moveMouse(e: any) {
        let left = this.map.getBounds().getEast()
        let right = this.map.getBounds().getWest()
        let top = this.map.getBounds().getNorth()
        let bottom = this.map.getBounds().getSouth()
        this.boundBox = new BoundBox(left, top, bottom, right)

        if (e.latlng) {
            var latlng = e.latlng as L.LatLng
            var lat = latlng.lat
            var lng = latlng.lng

            this.latLngControl.update({ lat: lat, lng: lng })
            this.setState({
                latlng: { lat: lat, lng: lng }
            })
        }
    }

    private deleteFilteredMapPoints(map: any) {
        if (this.layersAtivos) {
            Object.values(this.layersAtivos).forEach((layer: any) => {
                if (layer instanceof L.MarkerClusterGroup) {
                    this.map.removeLayer(layer);
                }
            });
            this.layersAtivos = {};
            this.shapesAtivos = {};
        }
    }

    private deleteLayer(layer: number) {
        for (var i = 0; i < this.listLayersAtivos.length; i++) {
            let item = this.listLayersAtivos[i]
            if (item == layer) {
                this.listLayersAtivos.splice(i, 0)
                break
            }
        }

        if (this.layersAtivos[layer]) {
            let layerAtivo = this.layersAtivos[layer] as Array<any>
            for (var i = 0; i < layerAtivo.length; i++) {
                this.map.removeLayer(this.layersAtivos[layer][i])
            }

            delete this.layersAtivos[layer]
        }
    }

    private clearAllLayers() {
        this.map.eachLayer((layer: any) => {

            if (!layer.options.type) {
                this.map.removeLayer(layer);
            }
        });
    }

    private setGeoJSON(scamada: Array<any>, geojson: Array<any>, tiles: Array<any>, isFilter?: boolean) {
        if (!this.map) return;
        if (this.layersAtivos[scamada[0]]) {
            let layerAtivo = this.layersAtivos[scamada[0]] as Array<any>
            for (var i = 0; i < layerAtivo.length; i++) {
                this.map.removeLayer(this.layersAtivos[scamada[0]][i])
            }

            delete this.layersAtivos[scamada[0]]
        }
        if (isFilter) {
            this.deleteFilteredMapPoints(this.map)
        }

        if (this.tilersAtivos[scamada[0]]) {
            delete this.tilersAtivos[scamada[0]]
        }
        if (tiles.length > 0) {
            tiles.forEach((itemTile) => {
                let tile = L.tileLayer(`${itemTile['caminhohttps']}{z}/{x}/{-y}.png`, { tms: true, maxZoom: 22 })
                tile.addTo(this.map)

                this.tilersAtivos[itemTile['scamada']] = tile
                this.layersAtivos[itemTile['scamada']] = [tile]
            })

            return
        }

        L.Polygon.addInitHook(function () {
            // @ts-ignore
            let keys = Object.keys(this._bounds)
            if (keys.length > 0) {
                // @ts-ignore
                this._latlng = this._bounds.getCenter();
            }
        });

        // Provide getLatLng and setLatLng methods for Leaflet.markercluster to be able to cluster polygons.
        L.Polygon.include({
            getLatLng: function () {
                return this._latlng;
            },
            setLatLng: function () { }
        });

        L.Polyline.addInitHook(function () {
            // @ts-ignore
            let keys = Object.keys(this._bounds)
            if (keys.length > 0) {
                // @ts-ignore
                this._latlng = this._bounds.getCenter();
            }
        })

        L.Polyline.include({
            getLatLng: function () {
                return this._latlng;
            },
            setLatLng: function () { }
        });
        geojson.forEach((item, index) => {
            if (item.Resultado) {
                return this.map
            } else {
                var feature = item.features as Array<any>
                var featureDataEmpty = feature.filter((x) => { return x.properties.sgeppontos == null })
                var featureDataNotEmpty = feature.filter((x) => { return x.properties.sgeppontos != null })

                if (feature[0].style.agrupa === 'S') {
                    var markersEmptyData = markerClusterGroup({
                        iconCreateFunction: (cluster) => {
                            return divIcon({ html: '<div>' + cluster.getChildCount() + '</div>', className: "clusterStyleEmptyData", iconSize: new Point(40, 40) });
                        }
                    })

                    markersEmptyData.addLayer(this.getLayerJSON(item, featureDataEmpty))
                    markersEmptyData.addTo(this.map)

                    var markersData = markerClusterGroup({
                        iconCreateFunction: (cluster) => {
                            return divIcon({ html: '<div>' + cluster.getChildCount() + '</div>', className: "clusterStyleData", iconSize: new Point(40, 40) });
                        }
                    })
                    markersData.addLayer(this.getLayerJSON(item, featureDataNotEmpty))
                    markersData.addTo(this.map)

                    this.layersAtivos[item.camada] = [markersEmptyData, markersData]


                } else {

                    const layerEmptyData = this.getLayerJSON(item, featureDataEmpty);
                    const layerData = this.getLayerJSON(item, featureDataNotEmpty);
                    layerEmptyData.addTo(this.map);
                    layerData.addTo(this.map);

                    this.layersAtivos[item.camada] = [layerEmptyData, layerData];
                }
            }

        })
    }

    private getLayerJSON(geojson: any, features: Array<any>) {
        geojson['features'] = features
        var layer = new GeoJSON(geojson, {
            style: geojson.style,
            pointToLayer: (feature, latlng) => {
                if (geojson.style) {
                    var iconUrl = feature['properties']['sgeppontos'] ? geojson.style.iconeDados : geojson.style.icone
                    if (iconUrl == undefined) {
                        return new Marker(latlng)
                    }

                    var icon = new Icon({
                        iconUrl: iconUrl
                    })

                    return new Marker(latlng, { icon: icon })
                } else {
                    return new Marker(latlng)
                }

            },
            onEachFeature: (feature, layer) => {
                this.shapesAtivos[feature['properties']['sshape']] = layer
                layer.on({
                    click: this.onLayerClick.bind(this)
                })
            }
        });

        return layer
    }

    public getLayerJSONSingle(geojson: any) {
        var layer = new GeoJSON(geojson, {
            style: geojson.style,
            onEachFeature: (feature, layer) => {
                layer.on({
                    click: this.onLayerClick.bind(this)
                })
            }
        });

        return layer
    }

    private cancelEdit() {
        this.reloadShapesCamadas(true)
        this.props.cancelEdit()
    }

    public getLayersAtivos(): any {
        return this.layersAtivos
    }

    public setLayersAtivos(scamada: Array<any>) {
        this.setState({ showLoading: true })
        new Request().requestPost('camada/buscar-shape', { scamada: scamada, boundBox: this.boundBox }).then((rs) => {
            scamada.forEach((item) => {
                this.listLayersAtivos.push(item)
            })
            if (Object.keys(this.state.storagedFilters).length === 0) {
                this.setGeoJSON([0], rs.Resultado.shape, rs.Resultado.tiles)
            } else if (typeof scamada[0] === 'number') {
                localStorage.removeItem('filters')
                this.setState({ storagedFilters: {} })
                this.setGeoJSON([0], rs.Resultado.shape, rs.Resultado.tiles)
            }
            this.setState({ showLoading: false });
        });
    }

    public reloadShapesCamadas(isCancel: boolean) {
        var scamada = Array<any>()
        var keys = Object.keys(this.layersAtivos)
        this.shapesAtivos = {}

        keys.forEach((key) => {
            scamada.push(key)
            this.map.removeLayer(this.layersAtivos[key])
            delete this.layersAtivos[key]
        });

        this.setLayersAtivos(scamada)
    }

    private buscarPosicaoAtual() {
        let latitudeStorage = localStorage.getItem("latitude")
        let longitudeStorage = localStorage.getItem("longitude")
        let zoomStorage = localStorage.getItem("zoom")

        if (latitudeStorage && longitudeStorage && zoomStorage) {
            this.setState({
                lat: parseFloat(latitudeStorage),
                lng: parseFloat(longitudeStorage),
                zoom: parseInt(zoomStorage)
            })
            return
        }

        new Request().requestPost('localizacao-inicial/buscar-localizacao', {}).then((rs) => {
            var resultado = rs.Resultado as Array<any>;
            if (resultado.length > 0) {
                this.setState({
                    lat: resultado[0].latitude,
                    lng: resultado[0].longitude
                })
            }
        });
    }

    private setFilterShapesStyle(shapes: Array<number>) {
        this.resetShapesFiltro();

        shapes.forEach((item) => {
            let layer = this.shapesAtivos[item]
            if (layer != undefined) {
                if (layer.feature.style['iconeFiltro'] != undefined) {
                    let icon = new Icon({
                        iconUrl: layer.feature.style['iconeFiltro']
                    })
                    layer.setIcon(icon)
                } else if (layer.feature.geometry.type != 'Point' && layer.feature.geometry.type != 'MultiPoint') {
                    var estilo = {
                        fillColor: layer.feature.style.fillColorFiltro,
                        color: layer.feature.style.colorFiltro
                    }
                    layer.setStyle(estilo);
                }
            }
        })

        this.shapesFiltros = shapes
    }

    private resetShapesFiltro() {
        this.shapesFiltros.forEach((item) => {
            let layer = this.shapesAtivos[item]
            if (layer != undefined) {
                if (layer.feature.style['iconeDados'] != undefined) {
                    let icon = new Icon({
                        iconUrl: layer.feature.style['iconeDados']
                    })

                    layer.setIcon(icon)
                } else if (layer.feature.geometry.type != 'Point' && layer.feature.geometry.type != 'MultiPoint') {
                    var estilo = {
                        fillColor: layer.feature.style.fillColor,
                        color: layer.feature.style.color
                    }
                    layer.setStyle(estilo);
                }
            }
        })
    }

    public exportPDF(title: string, footer: string) {
        var header = `
        <div class="header-mapa-pdf container-fluid">
            <div class="row header-mapa-pdf-bottom">
                <div class="col-2">
                    <image class="img-fluid" src="https://storage.googleapis.com/gep_images_production/geo/logo-login.png" />
                </div>
                <div class="col-8">
                    <h1 class="header-mapa-pdf-title">${title}</h1>
                </div>
            </div>
        </div>
        `

        let model = (L as any).BrowserPrint.Mode.Landscape(
            this.pageSizePDF,
            {
                header: { enabled: true, text: header },
                footer: { enabled: true, text: footer }
            }
        )

        this.map.printControl.browserPrint.print(model)
    }

    public setLoading(isLoading: boolean) {
        this.setState({ showLoading: isLoading })
    }

    public setShapeLegend(list: Array<any>) {
        this.shapeLegend = list
    }
}